import React from "react";
import im from '../assets/Benny-Button-favicon.png';

export default function Header() {
  return <div className="header">
    <div className="header-icon">
    <img src={im} alt="ChatBot Icon" width={60} height={60} />
    </div>
    <div className="header-text-invert">
    &nbsp;Wellbeing C🤖mpanion
    </div>
    </div>;
}
