import im from '../assets/Benny-Button-favicon.png';
import React, { useState, useEffect } from 'react';
import ChatBot from '../components/ChatBot';
import '../styles/styles.css';
import axios from 'axios';
import ContentfulAPI from '../services/ContentfulApi';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function ChatPage() {
    const baseURL = process.env.REACT_APP_API_DOMAIN;
    const contentfulReferenceIntroText = process.env.REACT_APP_CF_REF_INTRO_TEXT
    // const backgroundImageURL = "https://images.pexels.com/photos/773953/pexels-photo-773953.jpeg?auto=compress&cs=tinysrgb"
    // const baseURL = 'https://api.bennybutton.cloud';
    // const bbApiAccessKey = process.env.REACT_APP_API_ACCESS_KEY 
    // const bbApiAccessKey = 'FNQSWFG4H18LBB2ULPIT';
    const [dynamicContent, setDynamicContent] = useState( '' )
    const [showChatBot, setShowChatBot] = useState(false);
    const [apiError, setApiError] = useState(null);
    const [sessionID, setSessionID] = useState('');


    let getDynamicContentRendered = () => {
        loadChatbotMainContentFromContentful()?.await()
        return <div>
            <div className="titles">
                <p className="subtitle">The Benny Button B🤖t</p>
                <Markdown remarkPlugins={[remarkGfm]}>{dynamicContent}</Markdown>
                {/*<p></p>*/}
                {/*<p>🌟 #LiveWell #HaveImpact #BennyButton #BurnoutBot #BetaTesting #StressReliefRevolution</p>*/}
            </div>
        </div>
    }

    function loadChatbotMainContentFromContentful() {
        ContentfulAPI.getChatbotIntroText( contentfulReferenceIntroText ).then( (content) => {
                setDynamicContent( content );
            } ).catch( (error) => {
            console.error( error.message )
            setDynamicContent( "Nothing to show..." );
        } )
    }

    
    const toggleChatBot = () => {
        setShowChatBot((prevState) => !prevState);

        if (showChatBot === true){
            axios.post(`${baseURL}/v1/bot/dialogflow/start`)
            .then((response) => {
                
                if (response.data.status === 201) {
                    // Session started successfully, enable ChatBot
                    let body = JSON.parse(response.data.body);
                    setSessionID(body.session_id);
                } else {
                    // Session could not be started, handle the error
                    setApiError('Session could not be started!!!');
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setApiError(`Error: ${error.response.data}`);
                } else if (error.request) {
                    // The request was made but no response was received
                    setApiError('Network error occurred.');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setApiError(`Error: ${error.message}`);
                }
            });
        }

    };

    useEffect(() => {
        // Make an API request to start the session using Axios
        axios.post(`${baseURL}/v1/bot/dialogflow/start`)
            .then((response) => {
                if (response.data.status === 201) {
                    // Session started successfully, enable ChatBot
                    setShowChatBot(true);
                    let body = JSON.parse(response.data.body);
                    setSessionID(body.session_id);
                } else {
                    // Session could not be started, handle the error
                    setApiError('Session could not be started!!!');
                }
            })
            .catch((error) => {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setApiError(`Error: ${error.response.data}`);
                } else if (error.request) {
                    // The request was made but no response was received
                    setApiError('Network error occurred.');
                } else {
                    // Something happened in setting up the request that triggered an Error
                    setApiError(`Error: ${error.message}`);
                }
            });
    }, [baseURL]);


    // document.body.style = 'background: rgb(255 238 238);';
    // document.body.style.background = 'rgb(246 247 233)';

    // background images:
    // document.body.style.backgroundImage = `url("https://images.pexels.com/photos/34153/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350")`;
    // document.body.style.backgroundImage = `url("https://images.pexels.com/photos/1323712/pexels-photo-1323712.jpeg?auto=compress&cs=tinysrgb")`;
    document.body.style.backgroundImage = `url("https://ik.imagekit.io/bennybutton/pexels-daniel-jurin-1835712.jpg?auto=compress&cs=tinysrgb")`;
    // document.body.style.backgroundImage = `url("https://ik.imagekit.io/bennybutton/default-image.jpg?auto=compress&cs=tinysrgb")`;
    // document.body.style.backgroundImage = `url("https://images.pexels.com/photos/1835712/pexels-photo-1835712.jpeg?auto=compress&cs=tinysrgb")`;

    document.body.margin = '0px';
    document.body.style.backgroundPosition = 'center';
    document.body.style.backgroundSize = 'cover';
    document.body.style.backgroundRepeat = 'no-repeat';
    document.body.style.height = '100vh';
    document.body.style.width = '100vw';
    // setDynamicContent( getChatbotMainContentFromContentful() );

    return (
        <div id="container">
            <div id="container-header">
                <div className="one">
                    <header>
                        <div className="conti conti-nav">
                            <a href="/" className="logo">
                                <img src="https://images.squarespace-cdn.com/content/v1/582a490629687fac3b50e55c/f15df8b1-7665-4135-a0d7-280a632a0e25/Benny_Button_logo_2022.png" alt="bennybutton logo"/>
                            </a>
                            <nav>
                                <ul>
                                    {/*<li><a href="https://bennybutton.com">Website</a></li>*/}
                                    <li><a href="https://bennybutton.com">About</a></li>
                                    <li><a href="https://bennybutton.com/contact">Contact</a></li>
                                </ul>
                            </nav>
                        </div>
                    </header>

                </div>
            </div>
            <div id="container-bot">
                <div className="box box1">
                    {getDynamicContentRendered()}
                </div>
                <div className="chat-page">
                    {!apiError &&
                        <div className={`chatbot-icon ${showChatBot ? 'active' : ''}`} onClick={toggleChatBot}>
                            <div className='chatbot-icon-div'>
                                <img src={im} className="chatbot-icon" alt="ChatBot Icon" width={50} height={50}/>
                            </div>
                        </div>}

                    {apiError ? (
                        <div className="error-message">{apiError}</div>
                    ) : (
                        showChatBot && <ChatBot onClose={toggleChatBot} session_id={sessionID}/>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ChatPage;
