import React, { useState, useEffect, useRef } from "react";
import Markdown from 'react-markdown'
import ContentfulApi from '../services/ContentfulApi';
import e1 from '../assets/energy_1.png';
import e2 from '../assets/energy_2.png';
import e3 from '../assets/energy_3.png';
import e4 from '../assets/energy_4.png';
import s1 from '../assets/state_1.png';
import s2 from '../assets/state_2.png';
import s3 from '../assets/state_3.png';
import s4 from '../assets/state_4.png';
import breather from '../assets/breather_small.gif';
import rehypeExternalLinks from "rehype-external-links";

export default function BotMessage({ answer, msg_tiles, onTileClick, updateInput, sliderRef, tilesRef }) {
  const el = useRef(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [tiles, setTiles] = useState([]);
  const [showSlider, setShowSlider] = useState(false);
  const [sliderPosition, setSliderPosition] = useState(4);
  const [showImage, setShowImages] = useState(false);
  const [images, setImages] = useState([]);
  const [showBreatherGif, setShowBreatherGif] = useState(false);
  const [breatherGif, setBreatherGif] = useState([]);
  const [showArticle, setShowArticle] = useState(false);
  const [articleImage, setArticleImage] = useState([]);
  const [articleLink, setArticleLink] = useState("");
  const [articleTitle, setArticleTitle] = useState("");

  useEffect(() => {
      el.current.scrollIntoView({ block: "end", behavior: "smooth" });
    }, [loading]);
  
  useEffect(() => {
      setMessage(answer);
      setTiles(msg_tiles);

      if (msg_tiles && msg_tiles.length > 8 && !answer.startsWith( "For which factor would you" )) {
        setShowSlider(true);
        updateInput(msg_tiles[sliderPosition]);
      }

      else if (answer.trim() === "How’s your energy level?"){
        setImages([e1,e2,e3,e4])
        setShowImages(true);
      }

      else if (answer.trim() === "How’s your state of mind?"){
        setImages([s1,s2,s3,s4])
        setShowImages(true);
      }

      else if ( answer.trim().startsWith( "Take a Breather" ) || answer.trim().startsWith( "Take a Breather! Follow the visual" ) ) {
          setBreatherGif([breather])
          setShowBreatherGif(true);
      }

      else if ( answer.trim().startsWith( "Here is your Wellbeing Resource:" ) ) {
          let contentfulId = answer?.substring( 33, answer?.length );
          getWellbeingResource( contentfulId.trim() ).then( (response) => {
              // articleContent = contentfulId
              setMessage( "Here is your Wellbeing Resource:" )
              setShowArticle(true);
          }).catch( (error) => {
              console.error( error.message )
              setShowArticle(false);
          })
      }

      else if ( answer.startsWith( "{" ) /*&& answer.endsWith( "}" ) */ ) {
          setMessage( "Please open the link below:" )
          renderLLMResponse( answer )
      }

      else {
          setShowSlider(false);
          setShowArticle(false);
          // console.warn( "Unable to interpret answer: " + answer );
      }

      setLoading(false);
      
// eslint-disable-next-line    
  }, []);


  function renderLLMResponse( answer ) {
      // const actualPayload = answer.substring(0, answer.length - 3);
      let antwort = answer.split(", {", 2)[0]
      let parsedAnswer = JSON.parse( antwort );
      if ( parsedAnswer.uri ) {
          setArticleLink( parsedAnswer.uri );
      }
      if ( parsedAnswer.title ) {
          setArticleTitle( parsedAnswer.title );
      }
      setShowArticle( true )
  }

  async function getWellbeingResource( contentfulId ) {
      let entry = await ContentfulApi.getWellbeingResourceArticle( contentfulId.trim() );
      if (entry) {
          let imageURL = entry.fields["thumbnail"]?.fields["file"]["url"];
          if ( imageURL )
            setArticleImage( [ "https:"+imageURL ] );
          else
            setArticleImage( [] );

          setArticleLink( "/resource/" + contentfulId );
          setArticleTitle( entry.fields["title"] );
          setShowArticle(true);
      } else {
          setArticleImage( [ "https://images.squarespace-cdn.com/content/v1/582a490629687fac3b50e55c/f15df8b1-7665-4135-a0d7-280a632a0e25/Benny_Button_logo_2022.png" ] );
          setArticleLink( "https://www.bennybutton.com" );
          setArticleTitle( "Unfortunately there has been an error while trying to fetch your Wellbeing resource..." );
          setShowArticle(true);
      }
      return entry;
  }


  // function fetchWellbeingResource( contentfulId ){
  //     let response = BennyButtonRxApi.getWellbeingResourceArticle( contentfulId.trim() )
  //     response.then( (body) => {
  //         if ( body.thumbnail ) {
  //             let image = "https:" + body?.thumbnail
  //             setArticleImage( [ image ] );
  //         } else {
  //             setArticleImage( [] )
  //         }
  //
  //         let title = body?.title
  //         setArticleTitle( title )
  //
  //         setArticleLink( "https://www.bennybutton.com" );
  //
  //         return response
  //     } ).catch( (error) => {
  //         setArticleImage( [ "https://images.ctfassets.net/v98oetbd7ayp/5hO8TCmtDlHp9BQX8U6kiw/8927a56ef70cce5c4434a0e11bb4d1ea/Dan_Harris.jpg" ] );
  //         setArticleLink( "https://www.bennybutton.com" );
  //         setArticleTitle( "Unfortunately there has been an error: " + error.message );
  //         return error
  //     } )
  // }


  function handleSendTile(tileText) {
    onTileClick(tileText);
    setTiles([]);
  }

  function handleSliderChange(event) {
    setSliderPosition(event.target.value);
    updateInput(tiles[event.target.value]);
  }

  return (
    <div className="message-container-bot">
        <div className="bot-message"><Markdown rehypePlugins={[[rehypeExternalLinks, {target: '_blank'}]]}>{message}</Markdown></div>
      {showBreatherGif &&
          <div className="bot-breather">
              {showBreatherGif && <img src={breatherGif[0]} alt="breather-gif" height={250} width={200}/>}
          </div>
      }

      {showArticle &&
          <div className="bot-article">
              {/*eslint-disable-next-line*/}
              {showArticle && (articleImage.length > 0) && <img src={articleImage[0]} alt="article-image" width={200}/>}
              {showArticle && <p>{articleTitle}</p>}
              {/*eslint-disable-next-line*/}
              {showArticle && <a href={articleLink} target="_blank">Read/Open Article</a>}
          </div>
      }

      {showSlider &&
        <div ref = {sliderRef} className="bot-tiles-slider">
          <div className="slider-limits">{0}</div>
          <input    
            type="range"
            min={0}
            max={tiles.length - 1}
            value={sliderPosition}
            onChange={handleSliderChange}
          />
          <div className="slider-limits">{tiles.length - 1}</div>
        </div>
      }
      {tiles.length > 0 && !showSlider && <div ref = {tilesRef} className="bot-tiles">
          {tiles.map((tile, index) => (
            <div
              key={index}
              className="tile"
              onClick={() => handleSendTile(tile)}
            >
              {showImage && <img src={images[index]} alt={tile} height={50} width={50}/>}
              {tile}
            </div>
          ))}
      </div> }
      
      <div ref={el}></div>
    </div>
  );

}
